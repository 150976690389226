import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      height: '100vh',
      textAlign: 'center',
    },
    text: {
      paddingTop: theme.spacing(2),
      opacity: 0.6,
    }
  })
)

export default uiStyles