/**
 * Config
 */


const CONFIG = {
  url: `${process.env.REACT_APP_API}?api_key=${process.env.REACT_APP_API_KEY}`,
  googleApiKey: 'AIzaSyDfUzuwLoZ_N1e12-1uCsJKBhcClatWHWE',
  map:{
    closestMarkerToShow: 2,
    defaultCenter: { lat: 52.2276551, lng: 21.028163 },
    defaultZoom: 6,
    maxZoom: 19,
    cluster: {
      minZoom: 0,
        maxZoom: 16,
        radius: 60,
    },
    options: {
      maxZoom: 19,
    },
  }
}

export default CONFIG