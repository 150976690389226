import React from "react";

import uiStyles from "./uiStyles";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import AutocompleteUI from "@material-ui/lab/Autocomplete";
import {
  TextField,
  Paper,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ListBox from "./ListBox/ListBox";
import Highlighter from "react-highlight-words";
import { getParameterByName } from "../../utils";

function sanizateText(text) {
  return text
    .replace(/\u0141/g, "L")
    .replace(/\u0142/g, "l")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

interface Props extends WithStyles<typeof uiStyles> {
  data: any;
  onClick: any;
  onClickGeolocation: any;
  googleRef: any;
}

type State = {
  value: {
    label: string;
  };
  predictions: Array<any>;
};

class SearchBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: {
        label: "",
      },
      predictions: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value;
  }

  createList = () => {
    let list: Array<any> = [];
    const { predictions } = this.state;
    const scope = getParameterByName("scope");

    predictions.forEach((item) => {
      list.unshift({
        id: item.place_id,
        label: item.description,
        address: "",
        isPlace: true,
      });
    });

    this.props.data.forEach((item) => {
      if (scope === "taxpayers" || scope === "taxpayers_detailed") {
        list.push({
          id: item.id,
          label: item.taxpayer_company_name,
          tax_id: item.taxpayer_tax_id,
          number: item.number,
          reference_number: item.reference_number,
          latLng: {
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          },
        });
      } else {
        list.push({
          id: item.id,
          label: item.company.name,
          address: item.address,
          phones: item.phones,
          openingHours: item.opening_hours,
          emails: item.emails,
          websiteUrl: item.website_url,
          voivodeships: this.renderVoivodeshis(item.voivodeships),
          latLng: {
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          },
        });
      }
    });
    return list;
  };

  onClick = (option) => {
    if (option.isPlace) {
      this.getLocation(option.id).then((result) => {
        const latLng = {
          lat: Number(result[0].geometry.location.lat()),
          lng: Number(result[0].geometry.location.lng()),
        };

        this.props.onClick({ latLng, place: option.label });
      });
    } else {
      const latLng = option.latLng;
      const key = option.id;
      this.props.onClick({ latLng, key });
    }
  };

  renderVoivodeshis = (obj) => {
    let string: string = "";
    Object.values(obj).forEach((item, index) => {
      const comma = Object.values(obj).length - 1 === index ? "" : ", ";
      string += `${item}${comma}`;
    });
    return string;
  };

  handleOnChange = (e, input) => {
    const value = input && input.label ? input.label : "";
    this.setState({ value: { label: value } });
  };

  handleOnChangeInput = (e, input) => {
    this.setState({ value: { label: input } });

    if (input) {
      this.getGooglePlace(input);
    } else {
      this.setState({ predictions: [] });
    }
  };

  clearString = () => {};

  filterOptions = (options) => {
    const { label } = this.state.value;

    return options.filter(
      (item) =>
        (item.label &&
          sanizateText(item.label.toLowerCase()).includes(
            sanizateText(label.toLowerCase())
          )) ||
        (item.address &&
          sanizateText(item.address.toLowerCase()).includes(
            sanizateText(label.toLowerCase())
          )) ||
        (item.tax_id &&
          sanizateText(item.tax_id.toLowerCase()).includes(
            sanizateText(label.toLowerCase())
          )) ||
        (item.number &&
          sanizateText(item.number.toLowerCase()).includes(
            sanizateText(label.toLowerCase())
          )) ||
        (item.reference_number &&
          sanizateText(item.reference_number.toLowerCase()).includes(
            sanizateText(label.toLowerCase())
          ))
    );
  };

  getLocation = (id) => {
    const geocoder = new this.props.googleRef.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ placeId: id }, (results, status) => {
        if (status === "OK") {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  };

  getGooglePlace = (value) => {
    const { googleRef } = this.props;

    const service = new googleRef.places.AutocompleteService();
    const options = {
      input: value,
      types: ["(cities)"],
      componentRestrictions: { country: "pl" },
    };

    service.getPlacePredictions(options, (results, status) => {
      if (status === "OK") {
        this.setState({ predictions: results });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const scope = getParameterByName("scope");

    return (
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.paper}>
            <Button
              size="small"
              className={classes.link}
              startIcon={<GpsFixedIcon />}
              onClick={this.props.onClickGeolocation}
            >
              Użyj mojej lokalizacji
            </Button>
            <AutocompleteUI
              disableListWrap
              id="free-solo-demo"
              value={this.state.value}
              getOptionLabel={(option) => option.label}
              onInputChange={this.handleOnChangeInput}
              onChange={this.handleOnChange}
              ListboxComponent={ListBox as React.ComponentType<{}>}
              classes={{
                listbox: classes.noMargin,
                option: classes.noMargin,
              }}
              filterOptions={this.filterOptions}
              options={this.createList().map((option) => option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.input}
                  placeholder={
                    scope === "taxpayers" || scope === "taxpayers_detailed"
                      ? "Znajdź podatnika"
                      : "Znajdź dealera"
                  }
                  margin="normal"
                  name="input"
                  variant="outlined"
                />
              )}
              noOptionsText="Brak wyników"
              renderOption={(option, { inputValue }) => {
                return (
                  <Card
                    onClick={() => this.onClick(option)}
                    className={classes.card}
                    variant="outlined"
                  >
                    <CardContent className={classes.cardContent}>
                      <Typography
                        className={classes.heading}
                        variant="h6"
                        component="h2"
                      >
                        <Highlighter
                          searchWords={inputValue.split(" ")}
                          autoEscape={true}
                          textToHighlight={option.label}
                          sanitize={sanizateText}
                          highlightClassName={classes.highlightMark}
                        />
                      </Typography>
                      {option.address && (
                        <Typography variant="body2" component="p">
                          <Highlighter
                            searchWords={inputValue.split(" ")}
                            autoEscape={true}
                            textToHighlight={option.address}
                            sanitize={sanizateText}
                            highlightClassName={classes.highlightMark}
                          />
                        </Typography>
                      )}
                      {option.tax_id && (
                        <Typography variant="body2" component="p">
                          NIP:{" "}
                          <Highlighter
                            searchWords={inputValue.split(" ")}
                            autoEscape={true}
                            textToHighlight={option.tax_id}
                            sanitize={sanizateText}
                            highlightClassName={classes.highlightMark}
                          />
                        </Typography>
                      )}
                      {option.number && (
                        <Typography variant="body2" component="p">
                          Nr:{" "}
                          <Highlighter
                            searchWords={inputValue.split(" ")}
                            autoEscape={true}
                            textToHighlight={option.number}
                            sanitize={sanizateText}
                            highlightClassName={classes.highlightMark}
                          />
                        </Typography>
                      )}
                      {option.reference_number && (
                        <Typography variant="body2" component="p">
                          Nr ew.:{" "}
                          <Highlighter
                            searchWords={inputValue.split(" ")}
                            autoEscape={true}
                            textToHighlight={option.reference_number}
                            sanitize={sanizateText}
                            highlightClassName={classes.highlightMark}
                          />
                        </Typography>
                      )}
                    </CardContent>
                    <PlayArrowIcon className={classes.icon} />
                  </Card>
                );
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(uiStyles)(SearchBox);
