import React from 'react'

// UI
import {
  Link
} from '@material-ui/core'


/**
 * E-mail link
 */
export const EmailLink = ({ value }) => (
  <Link href={`mailto:${value}`}>{value}</Link>
)

/**
 * WWW
 */
export const WwwLink = ({ value }) => (
  <Link target="_blank" href={`http://${value}`}>{value}</Link>
)