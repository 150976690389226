import React from "react";
import CONFIG from "../../config";

import GoogleMapReact from "google-map-react";
import supercluster from "points-cluster";
import ClusterMarker from "../../components/ClusterMarker/ClusterMarker";
import Marker from "../../components/Marker/Marker";
import SearchBox from "../../components/SearchBox/SearchBox";
import screenfull from "screenfull";

interface Props {
  data: any;
}

type State = {
  googleMapRef: any;
  googleRef: any;
  points: any;
  myLocation: any;
  mapOptions: {
    center: any;
    zoom: number;
    bounds: any;
  };
  clusters: any;
};

class Map extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      googleMapRef: {},
      googleRef: {},
      points: [],
      myLocation: "",
      mapOptions: {
        center: CONFIG.map.defaultCenter,
        zoom: CONFIG.map.defaultZoom,
        bounds: [],
      },
      clusters: [],
    };
  }

  componentDidMount = () => this.setPoints(this.props.data, true);

  setPoints = (data: any, set: boolean): any => {
    let points: Array<any> = [];

    points =
      data &&
      data.map((item: any, index: number) => {
        const point = {
          id: Number(item.id),
          lat: item.latitude ? Number(item.latitude) : Number(item.lat),
          lng: item.longitude ? Number(item.longitude) : Number(item.lng),
          marker: item,
        };
        return point;
      });

    if (set) this.setState({ points });

    return points;
  };

  getIndex = (key): number =>
    this.state.clusters.findIndex((e) => e.id === key);
  closeWindowsInfo = (): void =>
    this.state.clusters.forEach((item) => (item.show = false));

  toggleWindowsInfo = (key): void => {
    const index = this.getIndex(key);
    const clusters = this.state.clusters;
    clusters[index].show = !clusters[index].show;
    this.setState({ clusters });
  };

  getClusters = (): any => {
    const clusters = supercluster(this.state.points, {
      minZoom: CONFIG.map.cluster.minZoom,
      maxZoom: CONFIG.map.cluster.maxZoom,
      radius: CONFIG.map.cluster.radius,
    });

    return clusters(this.state.mapOptions);
  };

  createClusters = (): void => {
    this.setState({
      clusters: this.state.mapOptions.bounds
        ? this.getClusters().map(({ wx, wy, numPoints, points }: any) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
            points,
            marker: points[0].marker,
          }))
        : [],
    });
  };

  handleMapChange = ({ center, zoom, bounds }: any) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      () => {
        this.createClusters();
      }
    );
  };

  getMapBounds = (maps, places): any => {
    if (places.length === 0) {
      return null;
    }
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  bindResizeListener = (map, maps, bounds): void => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  onMarkerClustererClick = (props): void => {
    const points = this.setPoints(props.points, false);

    const bounds = this.getMapBounds(this.state.googleRef, points);
    this.state.googleMapRef.fitBounds(bounds);
  };

  apiIsLoaded = (map, maps): void => {
    this.setState({
      googleMapRef: map,
      googleRef: maps,
    });

    const bounds = this.getMapBounds(maps, this.state.points);
    map.fitBounds(bounds);
    this.bindResizeListener(map, maps, bounds);
  };

  onChildClickCallback = (key, item): void => {
    const { googleMapRef, googleRef } = this.state;

    if (item && item.points) return;
    if (item.noClick) return;

    const clusters = this.state.clusters;
    const index = this.getIndex(key);

    // jezli potrzeba dodać offset dla mapy (clusters[index].lat)
    // const mapOffsetY = 0.09

    googleMapRef.panTo(
      new googleRef.LatLng(clusters[index].lat, clusters[index].lng)
    );
    this.closeWindowsInfo();
    googleRef.event.addListenerOnce(googleMapRef, "idle", () => {
      this.toggleWindowsInfo(key);
    });
  };

  onCloseInfoWindow = (key, item): void => this.toggleWindowsInfo(key);

  onListClick = ({ latLng, key, place }): void => {
    const { googleMapRef, googleRef } = this.state;

    if (place) {
      this.findClosestMarker(latLng, place);
      return;
    }
    googleMapRef.setZoom(15);
    googleMapRef.panTo(this.createLatLng(latLng.lat, latLng.lng));
    googleRef.event.addListenerOnce(googleMapRef, "idle", () => {
      let clusters = this.state.clusters;
      const index = clusters.findIndex((cluster) =>
        cluster.points.find((point) => point.id === key)
      );
      clusters[index].show = true;
      this.setState({ clusters });
    });
  };

  findClosestMarker = (latLng, place) => {
    const { googleRef, googleMapRef } = this.state;
    const markers = this.props.data;
    let distances = [];
    let points = [];
    var bounds = new googleRef.LatLngBounds();

    markers.forEach((item) => {
      const distance = googleRef.geometry.spherical.computeDistanceBetween(
        this.createLatLng(item.latitude, item.longitude),
        this.createLatLng(latLng.lat, latLng.lng)
      );
      distances.push({ id: item.id, distance });
    });

    place = place.split(",")[0];

    distances = distances.sort((a, b) => a.distance - b.distance);

    let foundInCity = false;
    let notFoundInCityCounter = 0;
    for (let { id } of distances) {
      const point = this.props.data.filter((e) => e.id === id)[0];

      points.push(point);

      if (point.address.includes(place)) {
        foundInCity = true;
        notFoundInCityCounter = 0;
      } else if (foundInCity && !point.address.includes(place)) {
        points.pop();
        notFoundInCityCounter++;

        if (notFoundInCityCounter > 3) {
          break;
        }
      }

      if (!foundInCity && points.length === CONFIG.map.closestMarkerToShow) {
        break;
      }
    }

    let minLat = latLng.lat;
    let minLng = latLng.lng;
    let maxLat = latLng.lat;
    let maxLng = latLng.lng;

    points.forEach((point) => {
      minLat = Math.min(minLat, parseFloat(point.latitude));
      minLng = Math.min(minLng, parseFloat(point.longitude));

      maxLat = Math.max(maxLat, parseFloat(point.latitude));
      maxLng = Math.max(maxLng, parseFloat(point.longitude));
    });

    const latDelta = Math.max(
      Math.abs(latLng.lat - minLat),
      Math.abs(maxLat - latLng.lat)
    );
    const lngDelta = Math.max(
      Math.abs(latLng.lng - minLng),
      Math.abs(maxLng - latLng.lng)
    );

    minLat = latLng.lat - latDelta;
    minLng = latLng.lng - lngDelta;
    bounds.extend(this.createLatLng(minLat, minLng));

    maxLat = latLng.lat + latDelta;
    maxLng = latLng.lng + lngDelta;
    bounds.extend(this.createLatLng(maxLat, maxLng));

    googleMapRef.fitBounds(bounds);
    const zoom = googleMapRef.getZoom();
    googleMapRef.setZoom(zoom > 14 ? 14 : zoom);

    // Fit all bounds once, when the map is ready
    googleRef.event.addListenerOnce(googleMapRef, "idle", () => {
      googleMapRef.fitBounds(bounds);
      const zoom = googleMapRef.getZoom();
      googleMapRef.setZoom(zoom > 14 ? 14 : zoom);
    });
  };

  createLatLng = (lat, lng) => new this.state.googleRef.LatLng(lat, lng);

  closestPoint = (points) => {
    let element = [];
    points.forEach((point) => {
      element.push(this.props.data.filter((e) => e.id === point.id)[0]);
    });
    return element;
  };

  onClickGeolocation = () => {
    const { googleMapRef, googleRef } = this.state;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          this.setState({
            myLocation: { lat: coords.latitude, lng: coords.longitude },
          });

          googleMapRef.setZoom(15);
          googleMapRef.panTo(
            new googleRef.LatLng(coords.latitude, coords.longitude)
          );
        },
        null,
        { enableHighAccuracy: true }
      );
    }
  };

  render() {
    return (
      <>
        <SearchBox
          googleRef={this.state.googleRef}
          onClickGeolocation={this.onClickGeolocation}
          onClick={this.onListClick}
          data={this.props.data}
        />
        <GoogleMapReact
          defaultZoom={CONFIG.map.defaultZoom}
          defaultCenter={CONFIG.map.defaultCenter}
          options={CONFIG.map.options}
          onChange={this.handleMapChange}
          yesIWantToUseGoogleMapApiInternals
          // bootstrapURLKeys={{ key: CONFIG.googleApiKey, libraries: "places, geometry, geocoder" }}
          onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
          onChildClick={this.onChildClickCallback}
          onTilesLoaded={() => {
            setTimeout(() => {
              const oldFullScreenButton = document.querySelector(
                ".gm-control-active.gm-fullscreen-control"
              );
              if (oldFullScreenButton) {
                const newFullscreenButton = oldFullScreenButton.cloneNode(true);
                oldFullScreenButton.parentNode.replaceChild(
                  newFullscreenButton,
                  oldFullScreenButton
                );

                newFullscreenButton.addEventListener("click", () => {
                  if (screenfull.isEnabled) {
                    if (!screenfull.isFullscreen) {
                      screenfull.request();
                    } else {
                      screenfull.exit();
                    }
                  } else {
                    alert(
                      "Tryb pełnoekranowy nie jest wspierany w twojej przeglądarce."
                    );
                  }
                });
              }
            }, 100);
          }}
          resetBoundsOnResize
        >
          {navigator.geolocation && this.state.myLocation && (
            <Marker
              noClick
              geolocation
              lat={this.state.myLocation.lat}
              lng={this.state.myLocation.lng}
            />
          )}
          {this.state.clusters.map((item: any, index) => {
            if (item.numPoints === 1) {
              return (
                <Marker
                  key={item.id}
                  index={item.id}
                  lat={item.points[0].lat}
                  lng={item.points[0].lng}
                  show={item.show}
                  marker={item.marker}
                  onClose={this.onCloseInfoWindow}
                />
              );
            }

            return (
              <ClusterMarker
                onClick={this.onMarkerClustererClick}
                key={item.id}
                lat={item.lat}
                lng={item.lng}
                points={item.points}
              />
            );
          })}
        </GoogleMapReact>
      </>
    );
  }
}

export default Map;
