import React from 'react'

// UI
import uiStyles from './uiStyles'
import {
  CssBaseline,
  Container
} from '@material-ui/core'


interface Props {
  children: React.ReactNode
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const classes = uiStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  )
}

export default AppLayout