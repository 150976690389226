import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      padding: 0,
    },
    container: {
      margin: 0,
      padding: '0 !important',
      position: 'relative',
      width: '100%',
      height: '100%'
    }
  })
)

export default uiStyles