import React from "react"
import { FixedSizeList, ListChildComponentProps } from 'react-window'


const heightElement = 100

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const listRef = React.createRef()
const outerRef = React.createRef()

function renderRow(props: ListChildComponentProps) {
  const { data, index, style, } = props
  
  return React.cloneElement(data[index], {
    style: {
      ...style,
    }
  })
}

const getHeight = (count) => {
  if(count === 1) return heightElement
  if(count === 2)return 2 * heightElement
  else return 2 * heightElement
}

  const ListBox = React.forwardRef<HTMLDivElement>(function ListBox(props, ref) {
    const { children, ...other } = props
    const itemData = React.Children.toArray(children)
    const itemCount = itemData.length
  
    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            ref={listRef}
            itemData={itemData}
            height={getHeight(itemCount)}
            width="100%"
            key={itemCount}
            outerElementType={OuterElementType}
            itemSize={heightElement}
            outerRef={outerRef}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });
export default ListBox