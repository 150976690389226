import React from "react";

import MarkerCounter from "./MarkerCounter";
import Cluster from "./Cluster";
import { getParameterByName } from "../../utils";

interface Props {
  lat: any;
  lng: any;
  name?: string;
  points: Array<any>;
  onClick?: any;
}

const ClusterMarker: React.FC<Props> = (props) => {
  const onClick = () => props.onClick(props);
  const scope = getParameterByName("scope");

  let length = props.points.length;

  if (scope === "fiscalizations") {
    length = props.points.reduce(
      (sum, point) => sum + point.marker.fiscalizations_count,
      0
    );
  }

  return (
    <MarkerCounter onClick={onClick}>
      <Cluster scale={1.2} length={length} />
    </MarkerCounter>
  );
};

export default ClusterMarker;
