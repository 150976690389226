import { Theme, createStyles } from "@material-ui/core/styles";

const uiStyles = (theme: Theme) =>
  createStyles({
    popup: {
      padding: "20px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    marginTop: {
      fontSize: 13,
    },
    link: {
      margin: "0 5px 0 3px",
    },
    alignRight: {
      textAlign: "right",
    },
    deviceInfoBox: {
      textAlign: "center",
      padding: theme.spacing(1),
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      "&:last-child": {
        borderRight: "none",
      },
    },
    grid: {
      position: "relative",
      marginTop: 15,
      zIndex: 120,
    },
    typography: {
      marginTop: 10,
    },
    bold: {
      fontWeight: "bold",
      marginLeft: 5,
    },
    popupTip: {
      width: 30,
      height: 15,
      borderStyle: "solid",
      borderWidth: "15px 15px 0 15px",
      borderColor: "#fff transparent transparent transparent",
      overflow: "initial",
      position: "absolute",
      left: "50%",
      marginLeft: "-190px",
      zIndex: 100,
    },
    icon: {
      marginRight: 5,
    },
    heading: {
      lineHeight: 1.2,
    },
    figures: {
      marginTop: 20,
    },
  });

export default uiStyles;
