import React from 'react'
import { COLORS } from '../../style-constants';

function Cluster(props) {
  const styles = {
    transform: `scale(${props.scale})`,
  };

  return (
    <svg
      width="40"
      height="40"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <circle cx="20" cy="20" r="15" strokeWidth="10" stroke={COLORS.secondary} fill={COLORS.secondary} strokeOpacity="0.6"/>
      <text x="50%" y="50%" textAnchor="middle" stroke="#00000" strokeWidth="2px" dy=".3em">{props.length}</text>
    </svg>
   
  )
}

export default Cluster