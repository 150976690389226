import React, { useState, useEffect } from "react";
import CONFIG from "../../config";

// UI
import "../../assets/styles/styles.scss";
import AppLayout from "../../components/AppLayout/AppLayout";
import Map from "../Map/Map";

import IntroSplash from "../../components/IntroSplash/IntroSplash";
import { getParameterByName } from "../../utils";

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const scope = getParameterByName("scope");
    const timeframe = getParameterByName("timeframe");
    const filter = getParameterByName("filter");
    const token = getParameterByName("token");

    // Get devices for map
    await fetch(
      CONFIG.url +
        (scope ? `&scope=${scope}` : "") +
        (timeframe ? `&timeframe=${timeframe}` : "") +
        (filter ? `&filter=${filter}` : "") +
        (token ? `&token=${encodeURIComponent(token)}` : ""),
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {loading ? (
        <IntroSplash />
      ) : (
        <AppLayout>
          <Map data={data} />
        </AppLayout>
      )}
    </>
  );
};

export default App;
