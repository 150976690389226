import React from 'react'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

function Geolocation(props) {
  const styles = {
    transform: `scale(${props.scale})`,
    color: '#1EA1F1',
    width: 20,
    height: 20,
    marginTop: -20,
    marginLeft: -10,
    zIndex: 200,
    cursor: 'default'
  }

  return (
    <RadioButtonCheckedIcon style={styles} />
  )
}

export default Geolocation

