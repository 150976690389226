import React from "react";

import MarkerStyled from "./MarkerStyled";
import Pointer from "./Pointer";
import Geolocation from "./Geolocation";
import InfoWindow from "../InfoWindow/InfoWindow";
import Popover from "@material-ui/core/Popover";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "440px",
      },
    },
  })
);

interface Props {
  index?: any;
  lat: any;
  lng: any;
  show?: boolean;
  marker?: any;
  onClose?: any;
  geolocation?: boolean;
  noClick?: boolean;
}

const Marker: React.FC<Props> = (props) => {
  const classes = useStyles();
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.geolocation ? (
        <Geolocation noClick scale="1" />
      ) : (
        <MarkerStyled onClick={handleClick} ref={anchorEl}>
          <Pointer
            scale="1"
            {...(props.marker &&
              (typeof props.marker.fiscalizations_count !== "undefined") !==
                undefined && {
                fiscalizations: props.marker.fiscalizations_count,
              })}
          />
        </MarkerStyled>
      )}
      <Popover
        classes={{
          paper: classes.popover,
        }}
        id={props.index}
        open={props.show || open}
        anchorEl={anchorEl.current}
        onClose={() => {
          handleClose();
          props.onClose(props.index);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <InfoWindow
          index={props.index}
          onClose={() => {
            handleClose();
            props.onClose(props.index);
          }}
          data={props.marker}
        />
      </Popover>
    </div>
  );
};

export default Marker;
