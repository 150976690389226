import styled from 'styled-components'
import { COLORS, easyMove } from '../../style-constants'

const MarkerStyled = styled.div`
  width: 20px;
  height: 35px;
  margin-top: -35px;
  margin-left: -10px;
  font-size: 14px;
  color: ${COLORS.primary};
  transition: transform 0.3s;
  animation: ${easyMove} 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
`

export default MarkerStyled