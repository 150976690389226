import React from "react";

import uiStyles from "./uiStyles";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Grid,
  IconButton,
  Link,
  Tooltip,
} from "@material-ui/core";
import NavigationIcon from "@material-ui/icons/Navigation";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";

import { EmailLink, WwwLink } from "../AppLinks/AppLinks";
import { getParameterByName } from "../../utils";

interface Props extends WithStyles<typeof uiStyles> {
  index: any;
  data: any;
  onClose: any;
}

type State = {
  showChooseButton: boolean;
  isAdmin: boolean;
};

const MAX_REPORT_COUNT = 1830;

class InfoWindow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showChooseButton: false,
      isAdmin: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      showChooseButton:
        window.location.search === "?scope=ecommerce" ? true : false,
      isAdmin: getParameterByName("is_admin") === "true",
    });
  };

  onClose = () => this.props.onClose(this.props.index);

  onClick = () =>
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${this.props.data.latitude},${this.props.data.longitude}`,
      "_blank"
    );

  onCompanyClick = () =>
    window.parent.postMessage(
      `/admin/companies/${this.props.data.company.id}`,
      "*"
    );

  onDeviceClick = () =>
    window.parent.postMessage(
      (this.state.isAdmin ? "/admin" : "") + `/devices/${this.props.data.id}`,
      "*"
    );

  handleClick = (props) => window.parent.postMessage(props, "*");

  render() {
    const scope = getParameterByName("scope");
    const { classes } = this.props;
    const {
      company,
      address,
      opening_hours,
      phones,
      emails,
      website_url,
      fiscalizations_count,
      in_service_count,
      taxpayer_company_name,
      taxpayer_tax_id,
      last_fiscal_report_number,
      next_inspection_at,
      last_daily_report_at,
      number,
      note,
    } = this.props.data;

    return (
      <div className={classes.popup}>
        {scope !== "taxpayers" && scope !== "taxpayers_detailed" ? (
          <>
            <Typography className={classes.heading} variant="h6" component="h2">
              {company.name}
            </Typography>
            <Typography variant="body2" component="p">
              {address}
            </Typography>
          </>
        ) : (
          <>
            <Typography className={classes.heading} variant="h6" component="h2">
              Podatnik
            </Typography>
            <Typography variant="body2" component="p">
              NIP: {taxpayer_tax_id}
            </Typography>
            <Typography variant="body2" component="p">
              Nazwa: {taxpayer_company_name}
            </Typography>
            <Typography variant="body2" component="p">
              Numer unikatowy:{" "}
              <Link
                component="button"
                variant="body2"
                onClick={this.onDeviceClick}
              >
                {number}
              </Link>
              {note && (
                <Tooltip title={note}>
                  <InfoOutlinedIcon
                    fontSize="small"
                    style={{ marginLeft: "0.25rem", verticalAlign: "middle" }}
                  />
                </Tooltip>
              )}
            </Typography>
          </>
        )}
        <Typography variant="body2" component="p"></Typography>
        {scope !== "fiscalizations" ? (
          <>
            {opening_hours && (
              <Typography
                className={classes.typography}
                variant="body2"
                component="p"
                color="textSecondary"
              >
                Godziny otwarcia:
                <br />
                {opening_hours}
                <br />
              </Typography>
            )}
            {phones && (
              <Typography
                className={classes.typography}
                variant="body2"
                component="p"
                color="textSecondary"
              >
                tel:
                {phones.map((item, index) => (
                  <Link
                    key={index}
                    className={classes.link}
                    href={`tel:${item}`}
                  >
                    {item}
                  </Link>
                ))}
              </Typography>
            )}
            {emails && (
              <Typography
                className={classes.marginTop}
                variant="body2"
                component="p"
                color="textSecondary"
              >
                e-mail:
                {emails.map((item, index) => (
                  <span key={index} className={classes.link}>
                    <EmailLink value={item} />
                  </span>
                ))}
              </Typography>
            )}
            {website_url && (
              <Typography
                className={classes.marginTop}
                variant="body2"
                component="p"
                color="textSecondary"
              >
                www: <WwwLink value={website_url} />
              </Typography>
            )}
            <Grid className={classes.grid} container>
              {this.state.showChooseButton && (
                <Grid xs={6} item>
                  <Button
                    onClick={() => this.handleClick(this.props.data)}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Wybierz
                  </Button>
                </Grid>
              )}
              {scope === "taxpayers" && company && (
                <Grid xs={6} item>
                  <Typography variant="body2" component="p">
                    <strong>Aktualny serwis</strong>
                  </Typography>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={this.onCompanyClick}
                  >
                    {company.short_name}
                  </Link>
                </Grid>
              )}
              {scope !== "taxpayers" && scope !== "taxpayers_detailed" && (
                <Grid className={classes.alignRight} xs={12} item>
                  <Button
                    onClick={this.onClick}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <NavigationIcon className={classes.icon} fontSize="small" />{" "}
                    Nawiguj
                  </Button>
                </Grid>
              )}

              {scope === "taxpayers_detailed" && (
                <>
                  <Grid xs={4} className={classes.deviceInfoBox} item>
                    Zapełnienie pamięci fiskalnej
                    <Typography variant="body1" component="p">
                      <strong>
                        {Math.round(
                          (last_fiscal_report_number / MAX_REPORT_COUNT) * 100
                        )}
                        %
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid xs={4} className={classes.deviceInfoBox} item>
                    Następny przegląd
                    <Typography variant="body1" component="p">
                      <strong>{next_inspection_at}</strong>
                    </Typography>
                  </Grid>
                  <Grid xs={4} className={classes.deviceInfoBox} item>
                    Ostatnia komunikacja
                    <Typography variant="body1" component="p">
                      <strong>
                        {last_daily_report_at
                          ? new Date(
                              last_daily_report_at.replace(/-/g, "/")
                            ).toLocaleDateString()
                          : "-"}
                      </strong>
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid className={classes.figures} container>
              <Grid xs={6} item>
                <Typography variant="body2" component="p">
                  <strong>Fiskalizacje</strong>
                </Typography>
                <Typography variant="body1">{fiscalizations_count}</Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography variant="body2" component="p">
                  <strong>Serwis</strong>
                </Typography>
                <Typography variant="body1">{in_service_count}</Typography>
              </Grid>
            </Grid>
            <Grid className={classes.grid} container>
              <Grid xs={6} item>
                <Button
                  onClick={this.onCompanyClick}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Przejdź do firmy
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={this.onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <span className={classes.popupTip}></span>
      </div>
    );
  }
}

export default withStyles(uiStyles)(InfoWindow);
