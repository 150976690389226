import React from 'react'
import ReactDOM from 'react-dom'

import App from './containers/App/App'
import * as serviceWorker from './serviceWorker'

import './assets/styles/styles.scss'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'



const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#005484',
      main: '#0078bd',
      dark: '#3393ca',
      contrastText: "#fff"
    },
    secondary: {
      light: '#ad733f',
      main: '#f8a55b',
      dark: '#f9b77b'
    },
  },
})

// DOM render
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
