import styled from 'styled-components'

const MarkerCounter = styled.div`
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
`

export default MarkerCounter