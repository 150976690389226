import { keyframes } from 'styled-components'

export const COLORS = {
  primary: '#0078bd',
  secondary: '#f8a55b',
  green: '#10e420'

}

export const easyMove = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`