import { createStyles, Theme } from "@material-ui/core/styles";

const uiStyles = (theme: Theme) =>
  createStyles({
    grid: {
      pointerEvents: "none",
      position: "absolute",
      zIndex: 100,
      padding: "20px",
    },
    paper: {
      pointerEvents: "all",
      backgroundColor: "#f3f3f3",
      boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.2)",
      width: "100%",
    },
    heading: {
      lineHeight: 1.2,
      fontSize: 17,
    },
    noMargin: {
      padding: 0,
      margin: 0,
    },
    typography: {
      marginTop: 10,
    },
    bold: {
      fontWeight: "bold",
      marginLeft: 5,
    },
    input: {
      width: "90%",
      marginLeft: "5%",
      marginBottom: 20,
    },
    link: {
      marginTop: 20,
      marginLeft: "5%",
    },
    card: {
      margin: 0,
      padding: 0,
      width: "100%",
      height: 100,
      overflowY: "scroll",
      cursor: "pointer",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&:hover": {
        background: "#D3D3D3",
      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    cardContent: {
      paddingRight: 30,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    icon: {
      position: "absolute",
      width: 20,
      height: 20,
      right: 10,
      top: "50%",
      marginTop: -10,
      color: theme.palette.secondary.main,
    },
    highlightMark: {
      backgroundColor: theme.palette.secondary.main,
    },
  });

export default uiStyles;
