import React from 'react'

// UI
import uiStyles from './uiStyles'
import {
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'

const IntroSplash: React.FC = () => {
  const classes = uiStyles(uiStyles)

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.grid}
    >
      <Grid item xs={6}>
        <CircularProgress size={60} thickness={2} />
        <Typography variant="overline" display="block" className={classes.text}>Ładowanie aplikacji</Typography>
      </Grid>
    </Grid>
  )
}

export default IntroSplash